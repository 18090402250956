<template>
  <div class="visualization-subtitle-list-container">
    <div class="subtitle-list-tag">
      <div class="subtitle-list-tag-title">
        <img
          src="../../../assets/image/tag.png"
          alt=""
          class="subtitle-list-tag-img"
        />
        <span class="subtitle-list-name">弹幕词云</span>
      </div>
    </div>

    <!-- 弹幕 -->
    <el-scrollbar style="height: 100%">
      <div class="subtitle-list-region" style="margin-top: 7px">
        <template v-if="subtitleList.length > 0">
          <div
            class="subtitle-list-main"
            style="margin-bottom: 30px"
            v-for="(item, index) in subtitleList"
            :key="`subtitle_list${index}`"
          >
            <div class="subtitle-list-info-box">
              <div class="subtitle-list-info">
                <div class="subtitle-avatar">
                  <img style="width: 30px; height: 30px" :src="item.avatar!=''?item.avatar:'../../../assets/image/blank_headPic.png'" />
                </div>
                <div class="subtitle-list-content">
                  <span class="subtitle-list-name">{{ item.name }}</span
                  >：{{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { getBarrageData } from "@/utils/apis";
export default {
  data() {
    return {
      timer: "",
      subtitleList: [],
    };
  },
  mounted() {
    this.getBarrageDatas(3000);
  },
    props: ["train", "studentInfo"],
  methods: {
    getBarrageDatas(senconds) {
      this.timer = window.setInterval(() => {
        let params = {
           user_id: this.train ? this.train.user_id : this.studentInfo?this.studentInfo.user_id:'',
          train_id: this.train ? this.train.train_id : this.studentInfo?this.studentInfo.train_id:'',
        };
        getBarrageData(params).then((res) => {
          if (res.code === 200) {
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                content: item.barrage_content,
                name: item.nickname,
                avatar: `http://onlive.e-class.me/${item.avatar}`,
              };
              arr.push(obj);
            });
            this.subtitleList = arr;
          }
        });
      }, senconds);
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__thumb {
  background: rgba(18, 196, 196, 1);
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: #040653;
  border-radius: 4px;
}
::v-deep .el-scrollbar__bar.is-vertical {
  background: #040653;
}

.visualization-subtitle-list-container {
  padding-right: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  padding-bottom: 0.1rem;
  box-sizing: border-box;
  .subtitle-list-tag {
    display: flex;
    margin: 20px 0 0 21px;

    .subtitle-list-tag-title {
      flex: 1;
      .subtitle-list-tag-img {
        margin-right: 12px;
      }
      .subtitle-list-name {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  .subtitle-list-region {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    margin-left: 30px;
    .scroll-content {
      height: 100%;
    }
    .subtitle-list-main {
      display: flex;
      align-items: center;

      .subtitle-list-time {
        margin-left: 14px;
        font-size: 14px;
        color: #fff;
      }
      .subtitle-list-info-box {
        position: relative;
        max-width: 85%;
        margin-left: 0.01rem;

        .subtitle-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          margin: 0 25px 0 5px;
          position: relative;
          img {
            position: absolute;
            border-radius: 50%;
          }
        }

        .subtitle-list-info {
          display: flex;
          padding: 0 0.2rem 0 0.1rem;
          min-height: 30px;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 15px;

          .subtitle-list-content {
            color: #fff;
            font-size: 0.14rem;
            padding: 0.08rem 0;
            .subtitle-list-name {
              height: 0.14rem;
              color: #12c4c4;
            }
          }
        }
      }
    }
  }
}
</style>
